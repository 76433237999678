// DO NOT MODIFY BY HAND!!
// Go through Readme.md

import { ReactComponent as AdvisorFilled } from './icon-advisor-filled.svg';
import { ReactComponent as AdvisorOutline } from './icon-advisor-outline.svg';
import { ReactComponent as AlertOctagon } from './icon-alertOctagon.svg';
import { ReactComponent as Amessage } from './icon-amessage.svg';
import { ReactComponent as Archive } from './icon-archive.svg';
import { ReactComponent as ArrowDown } from './icon-arrowDown.svg';
import { ReactComponent as ArrowUp } from './icon-arrowUp.svg';
import { ReactComponent as Assetplus } from './icon-assetplus.svg';
import { ReactComponent as Attachment } from './icon-attachment.svg';
import { ReactComponent as Bank } from './icon-bank.svg';
import { ReactComponent as BarChart01 } from './icon-barChart01.svg';
import { ReactComponent as BarChart02 } from './icon-barChart02.svg';
import { ReactComponent as Bond } from './icon-bond.svg';
import { ReactComponent as Book } from './icon-book.svg';
import { ReactComponent as Bookmark } from './icon-bookmark.svg';
import { ReactComponent as Briefcase } from './icon-briefcase.svg';
import { ReactComponent as Bulb } from './icon-bulb.svg';
import { ReactComponent as Calculator } from './icon-calculator.svg';
import { ReactComponent as Calendar } from './icon-calendar.svg';
import { ReactComponent as Camera } from './icon-camera.svg';
import { ReactComponent as CartFilled } from './icon-cart-filled.svg';
import { ReactComponent as CartOutline } from './icon-cart-outline.svg';
import { ReactComponent as Celebrate } from './icon-celebrate.svg';
import { ReactComponent as Chat } from './icon-chat.svg';
import { ReactComponent as CheckCircle } from './icon-check-circle.svg';
import { ReactComponent as CheckIntense } from './icon-check-intense.svg';
import { ReactComponent as Check } from './icon-check.svg';
import { ReactComponent as CheckOutline } from './icon-checkOutline.svg';
import { ReactComponent as Checklist } from './icon-checklist.svg';
import { ReactComponent as ChevronUpDouble } from './icon-chevron-up-double.svg';
import { ReactComponent as CircleOutline } from './icon-circleOutline.svg';
import { ReactComponent as ClappingFilled } from './icon-clapping-filled.svg';
import { ReactComponent as Clapping } from './icon-clapping.svg';
import { ReactComponent as Clock } from './icon-clock.svg';
import { ReactComponent as Close } from './icon-close.svg';
import { ReactComponent as CloseOutline } from './icon-closeOutline.svg';
import { ReactComponent as CoinsStacked } from './icon-coins-stacked.svg';
import { ReactComponent as ConsultFilled } from './icon-consult-filled.svg';
import { ReactComponent as ConsultOutline } from './icon-consult-outline.svg';
import { ReactComponent as Copy } from './icon-copy.svg';
import { ReactComponent as CreditCard } from './icon-credit-card.svg';
import { ReactComponent as CrossMark } from './icon-cross-mark.svg';
import { ReactComponent as Delete } from './icon-delete.svg';
import { ReactComponent as Document } from './icon-document.svg';
import { ReactComponent as DoubleCheck } from './icon-double-check.svg';
import { ReactComponent as DownRound } from './icon-down-round.svg';
import { ReactComponent as Down } from './icon-down.svg';
import { ReactComponent as DownloadArrow } from './icon-download-arrow.svg';
import { ReactComponent as Download } from './icon-download.svg';
import { ReactComponent as Drag } from './icon-drag.svg';
import { ReactComponent as Edit } from './icon-edit.svg';
import { ReactComponent as ErrorOutline } from './icon-error-outline.svg';
import { ReactComponent as Error } from './icon-error.svg';
import { ReactComponent as Event } from './icon-event.svg';
import { ReactComponent as Excel } from './icon-excel.svg';
import { ReactComponent as EyeOff } from './icon-eye-off.svg';
import { ReactComponent as Eye } from './icon-eye.svg';
import { ReactComponent as Faq } from './icon-faq.svg';
import { ReactComponent as FavFilled } from './icon-fav-filled.svg';
import { ReactComponent as FavOutline } from './icon-fav-outline.svg';
import { ReactComponent as FileAttachment } from './icon-fileAttachment.svg';
import { ReactComponent as Filter } from './icon-filter.svg';
import { ReactComponent as Folder } from './icon-folder.svg';
import { ReactComponent as FundFinder } from './icon-fundFinder.svg';
import { ReactComponent as Generate } from './icon-generate.svg';
import { ReactComponent as Gift } from './icon-gift.svg';
import { ReactComponent as GraduationHat } from './icon-graduation-hat.svg';
import { ReactComponent as Grid } from './icon-grid.svg';
import { ReactComponent as HandCoin } from './icon-handCoin.svg';
import { ReactComponent as HeartOutline } from './icon-heartOutline.svg';
import { ReactComponent as History } from './icon-history.svg';
import { ReactComponent as HomeFilled } from './icon-home-filled.svg';
import { ReactComponent as HomeOutline } from './icon-home-outline.svg';
import { ReactComponent as HorizontalMore } from './icon-horizontalMore.svg';
import { ReactComponent as Hourglass } from './icon-hourglass.svg';
import { ReactComponent as Image } from './icon-image.svg';
import { ReactComponent as Inbox } from './icon-inbox.svg';
import { ReactComponent as Income } from './icon-income.svg';
import { ReactComponent as Indicator } from './icon-indicator.svg';
import { ReactComponent as InfoIntense } from './icon-info-intense.svg';
import { ReactComponent as InfoSubtle } from './icon-info-subtle.svg';
import { ReactComponent as Info } from './icon-info.svg';
import { ReactComponent as InsuranceFilled } from './icon-insurance-filled.svg';
import { ReactComponent as InsuranceOutline } from './icon-insurance-outline.svg';
import { ReactComponent as InvestFilled } from './icon-invest-filled.svg';
import { ReactComponent as InvestOutline } from './icon-invest-outline.svg';
import { ReactComponent as JusticeScale } from './icon-justiceScale.svg';
import { ReactComponent as Language } from './icon-language.svg';
import { ReactComponent as Language02 } from './icon-language02.svg';
import { ReactComponent as Language1 } from './icon-language1.svg';
import { ReactComponent as Layers } from './icon-layers.svg';
import { ReactComponent as LeftArrow } from './icon-left-arrow.svg';
import { ReactComponent as Left } from './icon-left.svg';
import { ReactComponent as LikeFilled } from './icon-like-filled.svg';
import { ReactComponent as LineChart } from './icon-lineChart.svg';
import { ReactComponent as Link } from './icon-link.svg';
import { ReactComponent as List } from './icon-list.svg';
import { ReactComponent as Loader } from './icon-loader.svg';
import { ReactComponent as Loading } from './icon-loading.svg';
import { ReactComponent as Location } from './icon-location.svg';
import { ReactComponent as Lock } from './icon-lock.svg';
import { ReactComponent as LogOut } from './icon-logOut.svg';
import { ReactComponent as Loss } from './icon-loss.svg';
import { ReactComponent as Mail } from './icon-mail.svg';
import { ReactComponent as Marketing } from './icon-marketing.svg';
import { ReactComponent as Media2 } from './icon-media2.svg';
import { ReactComponent as MedicalCross } from './icon-medical-cross.svg';
import { ReactComponent as Message } from './icon-message.svg';
import { ReactComponent as Navigation } from './icon-navigation.svg';
import { ReactComponent as NegativeIntense } from './icon-negative-intense.svg';
import { ReactComponent as NegativeSubtle } from './icon-negative-subtle.svg';
import { ReactComponent as NotificationFilled } from './icon-notification-filled.svg';
import { ReactComponent as NotificationOutline } from './icon-notification-outline.svg';
import { ReactComponent as NotificationActiveFilled } from './icon-notificationActive-filled.svg';
import { ReactComponent as NotificationActiveOutline } from './icon-notificationActive-outline.svg';
import { ReactComponent as Passcode } from './icon-passcode.svg';
import { ReactComponent as Pdf } from './icon-pdf.svg';
import { ReactComponent as PencilFilled } from './icon-pencil-filled.svg';
import { ReactComponent as PencilOutline } from './icon-pencil-outline.svg';
import { ReactComponent as Phone } from './icon-phone.svg';
import { ReactComponent as Pin } from './icon-pin.svg';
import { ReactComponent as Play } from './icon-play.svg';
import { ReactComponent as Plus } from './icon-plus.svg';
import { ReactComponent as PlusCircle } from './icon-plusCircle.svg';
import { ReactComponent as PlusRounded } from './icon-plusRounded.svg';
import { ReactComponent as Podcast } from './icon-podcast.svg';
import { ReactComponent as Poster } from './icon-poster.svg';
import { ReactComponent as Ppt } from './icon-ppt.svg';
import { ReactComponent as Profile } from './icon-profile.svg';
import { ReactComponent as Profit } from './icon-profit.svg';
import { ReactComponent as RadioOn } from './icon-radio-on.svg';
import { ReactComponent as RefreshDouble } from './icon-refresh-double.svg';
import { ReactComponent as Refresh } from './icon-refresh.svg';
import { ReactComponent as Report } from './icon-report.svg';
import { ReactComponent as Retirement } from './icon-retirement.svg';
import { ReactComponent as Right } from './icon-right.svg';
import { ReactComponent as RocketFilled } from './icon-rocket-filled.svg';
import { ReactComponent as RocketOutline } from './icon-rocket-outline.svg';
import { ReactComponent as Rupee } from './icon-rupee.svg';
import { ReactComponent as Save } from './icon-save.svg';
import { ReactComponent as Scales } from './icon-scales.svg';
import { ReactComponent as SearchRefraction } from './icon-search-refraction.svg';
import { ReactComponent as Search } from './icon-search.svg';
import { ReactComponent as Send } from './icon-send.svg';
import { ReactComponent as SettingsFilled } from './icon-settings-filled.svg';
import { ReactComponent as SettingsOutline } from './icon-settings-outline.svg';
import { ReactComponent as ShareTool } from './icon-share-tool.svg';
import { ReactComponent as Share } from './icon-share.svg';
import { ReactComponent as Share02 } from './icon-share02.svg';
import { ReactComponent as Shield } from './icon-shield.svg';
import { ReactComponent as Shop } from './icon-shop.svg';
import { ReactComponent as Sort } from './icon-sort.svg';
import { ReactComponent as StarFilled } from './icon-star-filled.svg';
import { ReactComponent as StarOutline } from './icon-star-outline.svg';
import { ReactComponent as Support } from './icon-support.svg';
import { ReactComponent as Switch } from './icon-switch.svg';
import { ReactComponent as Team } from './icon-team.svg';
import { ReactComponent as TickRound } from './icon-tick-round.svg';
import { ReactComponent as Tool01 } from './icon-tool-01.svg';
import { ReactComponent as Tool } from './icon-tool.svg';
import { ReactComponent as Trash } from './icon-trash.svg';
import { ReactComponent as Truck } from './icon-truck.svg';
import { ReactComponent as Umbrella } from './icon-umbrella.svg';
import { ReactComponent as Unlock } from './icon-unlock.svg';
import { ReactComponent as UpRound } from './icon-up-round.svg';
import { ReactComponent as Up } from './icon-up.svg';
import { ReactComponent as Upload } from './icon-upload.svg';
import { ReactComponent as UserFilled } from './icon-user-filled.svg';
import { ReactComponent as UserOutline } from './icon-user-outline.svg';
import { ReactComponent as UserPlus } from './icon-user-plus.svg';
import { ReactComponent as UsersFilled } from './icon-users-filled.svg';
import { ReactComponent as UsersOutline } from './icon-users-outline.svg';
import { ReactComponent as Vault } from './icon-vault.svg';
import { ReactComponent as VerticalMore } from './icon-verticalMore.svg';
import { ReactComponent as Video } from './icon-video.svg';
import { ReactComponent as View } from './icon-view.svg';
import { ReactComponent as Wand } from './icon-wand.svg';
import { ReactComponent as WaringSubtle } from './icon-waring-subtle.svg';
import { ReactComponent as WarningIntense } from './icon-warning-intense.svg';
import { ReactComponent as Warning } from './icon-warning.svg';
import { ReactComponent as Webinar } from './icon-webinar.svg';
import { ReactComponent as Whatsapp } from './icon-whatsapp.svg';
import { ReactComponent as InfoCircle } from './info-circle.svg';

/**
 * @deprecated use APIcon instead with icon="advisorFilled"
 */
export function AdvisorFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <AdvisorFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="advisorOutline"
 */
export function AdvisorOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <AdvisorOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="alertOctagon"
 */
export function AlertOctagonIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <AlertOctagon height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="amessage"
 */
export function AmessageIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Amessage height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="archive"
 */
export function ArchiveIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Archive height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="arrowDown"
 */
export function ArrowDownIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <ArrowDown height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="arrowUp"
 */
export function ArrowUpIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <ArrowUp height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="assetplus"
 */
export function AssetplusIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Assetplus height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="attachment"
 */
export function AttachmentIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Attachment height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="bank"
 */
export function BankIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Bank height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="barChart01"
 */
export function BarChart01Icon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <BarChart01 height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="barChart02"
 */
export function BarChart02Icon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <BarChart02 height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="bond"
 */
export function BondIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Bond height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="book"
 */
export function BookIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Book height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="bookmark"
 */
export function BookmarkIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Bookmark height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="briefcase"
 */
export function BriefcaseIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Briefcase height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="bulb"
 */
export function BulbIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Bulb height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="calculator"
 */
export function CalculatorIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Calculator height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="calendar"
 */
export function CalendarIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Calendar height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="camera"
 */
export function CameraIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Camera height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="cartFilled"
 */
export function CartFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CartFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="cartOutline"
 */
export function CartOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CartOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="celebrate"
 */
export function CelebrateIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Celebrate height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="chat"
 */
export function ChatIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Chat height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="checkCircle"
 */
export function CheckCircleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CheckCircle height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="checkIntense"
 */
export function CheckIntenseIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CheckIntense height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="check"
 */
export function CheckIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Check height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="checkOutline"
 */
export function CheckOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CheckOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="checklist"
 */
export function ChecklistIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Checklist height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="chevronUpDouble"
 */
export function ChevronUpDoubleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <ChevronUpDouble height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="circleOutline"
 */
export function CircleOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CircleOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="clappingFilled"
 */
export function ClappingFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <ClappingFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="clapping"
 */
export function ClappingIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Clapping height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="clock"
 */
export function ClockIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Clock height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="close"
 */
export function CloseIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Close height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="closeOutline"
 */
export function CloseOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CloseOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="coinsStacked"
 */
export function CoinsStackedIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CoinsStacked height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="consultFilled"
 */
export function ConsultFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <ConsultFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="consultOutline"
 */
export function ConsultOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <ConsultOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="copy"
 */
export function CopyIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Copy height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="creditCard"
 */
export function CreditCardIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CreditCard height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="crossMark"
 */
export function CrossMarkIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <CrossMark height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="delete"
 */
export function DeleteIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Delete height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="document"
 */
export function DocumentIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Document height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="doubleCheck"
 */
export function DoubleCheckIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <DoubleCheck height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="downRound"
 */
export function DownRoundIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <DownRound height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="down"
 */
export function DownIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Down height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="downloadArrow"
 */
export function DownloadArrowIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <DownloadArrow height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="download"
 */
export function DownloadIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Download height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="drag"
 */
export function DragIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Drag height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="edit"
 */
export function EditIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Edit height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="errorOutline"
 */
export function ErrorOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <ErrorOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="error"
 */
export function ErrorIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Error height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="event"
 */
export function EventIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Event height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="excel"
 */
export function ExcelIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Excel height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="eyeOff"
 */
export function EyeOffIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <EyeOff height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="eye"
 */
export function EyeIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Eye height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="faq"
 */
export function FaqIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Faq height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="favFilled"
 */
export function FavFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <FavFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="favOutline"
 */
export function FavOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <FavOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="fileAttachment"
 */
export function FileAttachmentIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <FileAttachment height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="filter"
 */
export function FilterIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Filter height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="folder"
 */
export function FolderIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Folder height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="fundFinder"
 */
export function FundFinderIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <FundFinder height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="generate"
 */
export function GenerateIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Generate height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="gift"
 */
export function GiftIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Gift height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="graduationHat"
 */
export function GraduationHatIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <GraduationHat height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="grid"
 */
export function GridIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Grid height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="handCoin"
 */
export function HandCoinIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <HandCoin height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="heartOutline"
 */
export function HeartOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <HeartOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="history"
 */
export function HistoryIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <History height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="homeFilled"
 */
export function HomeFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <HomeFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="homeOutline"
 */
export function HomeOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <HomeOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="horizontalMore"
 */
export function HorizontalMoreIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <HorizontalMore height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="hourglass"
 */
export function HourglassIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Hourglass height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="image"
 */
export function ImageIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Image height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="inbox"
 */
export function InboxIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Inbox height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="income"
 */
export function IncomeIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Income height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="indicator"
 */
export function IndicatorIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Indicator height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="infoIntense"
 */
export function InfoIntenseIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <InfoIntense height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="infoSubtle"
 */
export function InfoSubtleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <InfoSubtle height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="info"
 */
export function InfoIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Info height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="insuranceFilled"
 */
export function InsuranceFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <InsuranceFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="insuranceOutline"
 */
export function InsuranceOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <InsuranceOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="investFilled"
 */
export function InvestFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <InvestFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="investOutline"
 */
export function InvestOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <InvestOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="justiceScale"
 */
export function JusticeScaleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <JusticeScale height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="language"
 */
export function LanguageIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Language height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="language02"
 */
export function Language02Icon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Language02 height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="language1"
 */
export function Language1Icon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Language1 height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="layers"
 */
export function LayersIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Layers height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="leftArrow"
 */
export function LeftArrowIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <LeftArrow height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="left"
 */
export function LeftIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Left height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="likeFilled"
 */
export function LikeFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <LikeFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="lineChart"
 */
export function LineChartIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <LineChart height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="link"
 */
export function LinkIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Link height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="list"
 */
export function ListIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <List height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="loader"
 */
export function LoaderIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Loader height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="loading"
 */
export function LoadingIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Loading height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="location"
 */
export function LocationIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Location height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="lock"
 */
export function LockIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Lock height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="logOut"
 */
export function LogOutIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <LogOut height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="loss"
 */
export function LossIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Loss height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="mail"
 */
export function MailIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Mail height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="marketing"
 */
export function MarketingIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Marketing height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="media2"
 */
export function Media2Icon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Media2 height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="medicalCross"
 */
export function MedicalCrossIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <MedicalCross height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="message"
 */
export function MessageIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Message height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="navigation"
 */
export function NavigationIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Navigation height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="negativeIntense"
 */
export function NegativeIntenseIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <NegativeIntense height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="negativeSubtle"
 */
export function NegativeSubtleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <NegativeSubtle height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="notificationFilled"
 */
export function NotificationFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <NotificationFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="notificationOutline"
 */
export function NotificationOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <NotificationOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="notificationActiveFilled"
 */
export function NotificationActiveFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <NotificationActiveFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="notificationActiveOutline"
 */
export function NotificationActiveOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <NotificationActiveOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="passcode"
 */
export function PasscodeIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Passcode height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="pdf"
 */
export function PdfIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Pdf height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="pencilFilled"
 */
export function PencilFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <PencilFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="pencilOutline"
 */
export function PencilOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <PencilOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="phone"
 */
export function PhoneIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Phone height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="pin"
 */
export function PinIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Pin height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="play"
 */
export function PlayIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Play height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="plus"
 */
export function PlusIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Plus height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="plusCircle"
 */
export function PlusCircleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <PlusCircle height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="plusRounded"
 */
export function PlusRoundedIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <PlusRounded height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="podcast"
 */
export function PodcastIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Podcast height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="poster"
 */
export function PosterIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Poster height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="ppt"
 */
export function PptIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Ppt height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="profile"
 */
export function ProfileIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Profile height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="profit"
 */
export function ProfitIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Profit height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="radioOn"
 */
export function RadioOnIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <RadioOn height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="refreshDouble"
 */
export function RefreshDoubleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <RefreshDouble height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="refresh"
 */
export function RefreshIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Refresh height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="report"
 */
export function ReportIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Report height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="retirement"
 */
export function RetirementIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Retirement height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="right"
 */
export function RightIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Right height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="rocketFilled"
 */
export function RocketFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <RocketFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="rocketOutline"
 */
export function RocketOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <RocketOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="rupee"
 */
export function RupeeIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Rupee height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="save"
 */
export function SaveIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Save height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="scales"
 */
export function ScalesIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Scales height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="searchRefraction"
 */
export function SearchRefractionIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <SearchRefraction height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="search"
 */
export function SearchIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Search height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="send"
 */
export function SendIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Send height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="settingsFilled"
 */
export function SettingsFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <SettingsFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="settingsOutline"
 */
export function SettingsOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <SettingsOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="shareTool"
 */
export function ShareToolIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <ShareTool height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="share"
 */
export function ShareIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Share height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="share02"
 */
export function Share02Icon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Share02 height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="shield"
 */
export function ShieldIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Shield height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="shop"
 */
export function ShopIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Shop height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="sort"
 */
export function SortIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Sort height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="starFilled"
 */
export function StarFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <StarFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="starOutline"
 */
export function StarOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <StarOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="support"
 */
export function SupportIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Support height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="switch"
 */
export function SwitchIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Switch height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="team"
 */
export function TeamIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Team height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="tickRound"
 */
export function TickRoundIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <TickRound height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="tool01"
 */
export function Tool01Icon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Tool01 height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="tool"
 */
export function ToolIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Tool height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="trash"
 */
export function TrashIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Trash height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="truck"
 */
export function TruckIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Truck height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="umbrella"
 */
export function UmbrellaIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Umbrella height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="unlock"
 */
export function UnlockIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Unlock height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="upRound"
 */
export function UpRoundIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <UpRound height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="up"
 */
export function UpIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Up height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="upload"
 */
export function UploadIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Upload height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="userFilled"
 */
export function UserFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <UserFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="userOutline"
 */
export function UserOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <UserOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="userPlus"
 */
export function UserPlusIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <UserPlus height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="usersFilled"
 */
export function UsersFilledIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <UsersFilled height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="usersOutline"
 */
export function UsersOutlineIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <UsersOutline height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="vault"
 */
export function VaultIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Vault height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="verticalMore"
 */
export function VerticalMoreIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <VerticalMore height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="video"
 */
export function VideoIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Video height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="view"
 */
export function ViewIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <View height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="wand"
 */
export function WandIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Wand height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="waringSubtle"
 */
export function WaringSubtleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <WaringSubtle height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="warningIntense"
 */
export function WarningIntenseIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <WarningIntense height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="warning"
 */
export function WarningIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Warning height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="webinar"
 */
export function WebinarIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Webinar height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="whatsapp"
 */
export function WhatsappIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <Whatsapp height={size + "px"} width={size + "px"} color={color} />
}

/**
 * @deprecated use APIcon instead with icon="infoCircle"
 */
export function InfoCircleIcon({ size = 24, color = "#232730" }: { size?: number, color?: string }) {
    return <InfoCircle height={size + "px"} width={size + "px"} color={color} />
}